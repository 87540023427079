var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext } from "react";
import { View, Text } from "react-native";
import { Button } from "../Button";
import tw from "../../../lib/tailwind";
import { WalkthroughContext, } from "react-native-walkthrough";
export const buildGuides = (items) => {
    return items.map((item, _) => {
        const { id, title, description } = item, rest = __rest(item, ["id", "title", "description"]);
        return Object.assign(Object.assign({}, rest), { id, content: (<Tooltip title={title} description={description} items={items}/>), tooltipProps: { contentStyle: tw `p-0 rounded-2` } });
    });
};
export const Tooltip = ({ title, description, items }) => {
    const { currentElement, goToNext } = useContext(WalkthroughContext);
    return (<View style={tw `bg-[#171C1A] py-5 px-4 rounded-2`}>
      <Text style={tw `text-white font-500 text-lg mb-1`}>{title}</Text>
      <Text style={tw `text-white text-base mb-4`}>{description}</Text>
      <View style={tw `flex-row items-center justify-between`}>
        <View style={tw `flex-row`}>
          {items.map(({ id }) => {
            return (<View style={tw.style(`border-brand-green-accent border-2 w-4 h-4 rounded-full mr-1.5`, currentElement.id === id && "bg-brand-green-accent")}/>);
        })}
        </View>
        <Button title={"Next"} onPress={goToNext} type={"short"} containerStyle={tw `bg-brand-green-accent`} textStyle={tw `text-white`}/>
      </View>
    </View>);
};
