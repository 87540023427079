import React from "react";
import { View, Text } from "react-native";
import tw from "../../../lib/tailwind";
import { BlurView } from "expo-blur";
export const Card = ({ title, description, leftChild, rightChild, contentContainerStyle, containerStyle, titleStyle, descriptionStyle, leftChildContainerStyle, rightChildContainerStyle, blurIntensity = 0, children, }) => {
    return (<View style={tw.style("flex-row items-center w-full p-3 relative overflow-hidden", containerStyle)}>
      {!!blurIntensity && (<BlurView intensity={blurIntensity} tint={"dark"} style={tw `h-full w-full absolute z-1 left-0 top-0`}/>)}
      {!!leftChild && (<View style={tw.style(`mr-4 z-2`, leftChildContainerStyle)}>
          {leftChild}
        </View>)}
      <View style={tw.style(`flex-1 items-start`, contentContainerStyle)}>
        {typeof title === "string" ? (<Text style={tw.style("text-base text-black font-500", titleStyle)} numberOfLines={1}>
            {title}
          </Text>) : (title)}
        {typeof description === "string" ? (<Text style={tw.style("text-sm text-gray-600", descriptionStyle)} numberOfLines={1}>
            {description}
          </Text>) : (description)}
        {children}
      </View>
      {!!rightChild && (<View style={tw.style(`ml-4 z-2`, rightChildContainerStyle)}>
          {rightChild}
        </View>)}
    </View>);
};
