var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Text, View } from "react-native";
import Modal from "react-native-modal";
import { Button } from "../storybook/stories/Button";
import tw from "../lib/tailwind";
const MessageModal = (_a) => {
    var { title, titleStyle, renderContent, buttonProps, closeModal, icon, iconContainerStyle, containerStyle } = _a, modalProps = __rest(_a, ["title", "titleStyle", "renderContent", "buttonProps", "closeModal", "icon", "iconContainerStyle", "containerStyle"]);
    return (<Modal onBackdropPress={closeModal} onBackButtonPress={closeModal} style={tw `m-0`} {...modalProps}>
      <View style={tw.style(`bg-brand-green-400 flex-1 px-4 pb-8`, containerStyle)}>
        <View style={tw `flex-1 my-24`}>
          <View style={tw.style(`bg-white rounded-full p-1 self-center mb-12`, iconContainerStyle)}>
            {icon}
          </View>
          <Text style={tw.style(`text-white text-center text-lg font-500`, titleStyle)}>
            {title}
          </Text>
          {renderContent === null || renderContent === void 0 ? void 0 : renderContent({ closeModal })}
        </View>
        {!!buttonProps && <Button {...buttonProps}/>}
      </View>
    </Modal>);
};
export default MessageModal;
