import React from "react";
import { View, StyleSheet, Text } from "react-native";
import StyleGuide from "../../../lib/style-guide";
export const ProgressBar = ({ style, containerStyle, progressStyle, progress, progressText, }) => {
    const width = { width: `${progress}%` };
    const marginLeft = { marginLeft: `${progress - 10}%` };
    return (<View style={[styles.container, style]}>
      {progressText ? (<Text style={[styles.progressText, marginLeft]}>{progressText}</Text>) : null}
      <View style={[styles.progressContainer, containerStyle]}>
        <View style={[styles.progress, progressStyle, width]}/>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        width: "100%",
    },
    progressContainer: {
        width: "100%",
        height: 5,
        backgroundColor: StyleGuide.Colors.shades.grey[50],
        borderRadius: 1,
    },
    progress: {
        height: "100%",
        backgroundColor: StyleGuide.Colors.shades.green[200],
    },
    progressText: {
        color: StyleGuide.Colors.shades.green[200],
        fontSize: StyleGuide.Typography[10],
        fontWeight: "500",
        lineHeight: 14,
        marginBottom: 1,
    },
});
