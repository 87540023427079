export default {
    primary: "#25A36E",
    secondary: "",
    black: "#000",
    white: "#FFF",
    shades: {
        grey: {
            25: "#F7F8F7",
            30: "#ECEFED",
            50: "#E4E4E4",
            100: "#8D8D8D",
            200: "#4B4B4B",
            300: "#383B44",
            400: "#A3A3A3",
            500: "#F2F2F2",
            600: "#EBEBEB",
            700: "#F5F5F5",
            800: "#666A70",
            900: "#7F858E",
            1000: "#DDDDDD",
            1050: "#CECECE",
            1100: "#D8DEDC",
            1150: "#171C1A",
            1200: "#5C6B65",
            1250: "#B9B9B9",
            1300: "#2D3431",
            1350: "#EBE6E6",
            1400: "#ECECEC",
            1450: "#5E5959",
            1500: '#1C1C1C'
        },
        green: {
            100: "#73C567",
            200: "#1B8B70",
            300: "#104B3D",
            400: "#C6D9D3",
            500: "#6CCAA8",
            600: "#25A36E",
            700: "#1A5225",
            800: "#0EC99C",
            900: "#E7FFEE",
        },
        blue: {
            100: "#90D0FF",
            200: "#3E7DF1",
            300: "#1359C4",
            400: "#1359C4",
            500: "#C7DBFA",
            600: "#1359C4",
            700: "#0091FE",
            800: "#D8E6FB",
            900: "#386EC4",
        },
        orange: {
            100: "#E1854A",
            200: "#FFC59A",
            300: "#F5AC3D",
            400: "#F5AB3D",
            500: "#FFF5DB",
        },
        purple: {
            100: "#8567C5",
        },
        red: {
            25: "#FAEBEB",
            50: "#FAEBEB",
            100: "#E85656",
            200: "#FFEDE9",
            300: "#DB5B5B",
            400: "#F8DEDE",
            500: "#DB5B5B",
            600: "#BE0303",
            700: "#AD2C2C",
        },
    },
};
