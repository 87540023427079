import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgCancel = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M8.47 8.47a.75.75 0 0 1 1.06 0L12 10.94l2.47-2.47a.75.75 0 1 1 1.06 1.06L13.06 12l2.47 2.47a.75.75 0 1 1-1.06 1.06L12 13.06l-2.47 2.47a.75.75 0 0 1-1.06-1.06L10.94 12 8.47 9.53a.75.75 0 0 1 0-1.06Z"
      fill={props.color}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.317 3.769a42.502 42.502 0 0 1 9.366 0c1.827.204 3.302 1.643 3.516 3.48.37 3.156.37 6.346 0 9.503-.215 1.837-1.69 3.275-3.516 3.48a42.511 42.511 0 0 1-9.366 0c-1.827-.205-3.302-1.643-3.516-3.48a40.902 40.902 0 0 1 0-9.504c.214-1.836 1.69-3.275 3.516-3.48Zm9.2 1.49a41.001 41.001 0 0 0-9.034 0A2.486 2.486 0 0 0 5.29 7.423a39.403 39.403 0 0 0 0 9.154 2.486 2.486 0 0 0 2.193 2.163c2.977.333 6.057.333 9.034 0a2.486 2.486 0 0 0 2.192-2.163 39.401 39.401 0 0 0 0-9.154 2.486 2.486 0 0 0-2.192-2.164Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgCancel;
