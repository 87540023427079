var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useCallback, useContext, useState, } from "react";
import { SearchModal } from "../modals/SearchModal";
import { LoadingModal } from "../modals/LoadingModal";
import { FullModal } from "../modals/FullModal";
import { BottomHalfModal, } from "../modals/BottomHalfModal";
import { OptionsModal } from "../modals/OptionsModal";
import MessageModal from "../modals/MessageModal";
import PinVerificationModal from "../modals/PinVerificationModal";
const defaultModalPropsList = {
    loading: { text: "" },
    "bottom-half": {
        renderContent: () => null,
    },
    full: {
        renderContent: () => null,
    },
    options: {
        options: [],
    },
    message: {
        title: "",
        icon: null,
        renderContent: () => null,
    },
    search: {
        items: [],
        renderItem: () => null,
        setFilter: () => false,
    },
    "pin-verification": {
        pageInfo: {
            key: "",
            title: "",
            headerTitle: "",
        },
        onPinSubmit: () => __awaiter(void 0, void 0, void 0, function* () { }),
    },
};
const defaultModalVisibility = Object.keys(defaultModalPropsList).reduce((acc, curr) => {
    return Object.assign(Object.assign({}, acc), { [curr]: false });
}, {});
const ModalContext = createContext({});
export const useModal = () => {
    return useContext(ModalContext);
};
export const withModal = (Component) => (props) => {
    const [modalVisibility, setModalVisibility] = useState(defaultModalVisibility);
    const [modalPropsList, setModalPropsList] = useState(defaultModalPropsList);
    const closeModal = useCallback(() => {
        setModalVisibility(defaultModalVisibility);
        setModalPropsList(defaultModalPropsList);
    }, []);
    const openModal = useCallback((modalType, modalProps) => {
        setModalPropsList((prevModalPropsList) => {
            return Object.assign(Object.assign({}, prevModalPropsList), { [modalType]: modalProps });
        });
        setModalVisibility((prevModalVisibility) => {
            return Object.assign(Object.assign({}, prevModalVisibility), { [modalType]: true });
        });
        return closeModal;
    }, [closeModal]);
    return (<ModalContext.Provider value={{
            openModal,
            closeModal,
        }}>
        <Component {...props} openModal={openModal} closeModal={closeModal}/>
        <LoadingModal isVisible={modalVisibility.loading} closeModal={closeModal} {...modalPropsList.loading}/>
        <BottomHalfModal isVisible={modalVisibility["bottom-half"]} closeModal={closeModal} {...modalPropsList["bottom-half"]}/>
        <FullModal isVisible={modalVisibility.full} closeModal={closeModal} {...modalPropsList.full}/>
        <OptionsModal isVisible={modalVisibility.options} closeModal={closeModal} {...modalPropsList.options}/>
        <SearchModal isVisible={modalVisibility.search} closeModal={closeModal} {...modalPropsList.search}/>
        <MessageModal isVisible={modalVisibility.message} closeModal={closeModal} {...modalPropsList.message}/>
        <PinVerificationModal isVisible={modalVisibility["pin-verification"]} closeModal={closeModal} {...modalPropsList["pin-verification"]}/>
      </ModalContext.Provider>);
};
