var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Card } from "../Card";
import tw from "../../../lib/tailwind";
import ArrowLeft from "../../../assets/icons/components/Solid/Interface/ArrowLeft";
import { Pressable } from "react-native";
import { Avatar } from "../Avatar";
export const Header = (_a) => {
    var { mode = "default", title, onBackPress } = _a, props = __rest(_a, ["mode", "title", "onBackPress"]);
    return (<Card {...props} title={title} titleStyle={tw `text-black font-bold text-base min-h-[24px]`} contentContainerStyle={tw.style({ default: "items-center", business: "" }[mode])} containerStyle={tw.style("bg-white px-4", { default: "justify-center relative py-3.5", business: "" }[mode])} leftChild={mode === "business" && typeof title === "string" ? (<Avatar name={title} size={"small"} containerStyle={tw `mr-3 w-8 h-8 rounded-5`} textStyle={tw `text-sm`} textContainerStyle={tw `bg-transparent`}/>) : onBackPress ? (<Pressable style={tw ``} onPress={onBackPress}>
            <ArrowLeft width={32} height={32} color="#000"/>
          </Pressable>) : null} leftChildContainerStyle={tw.style({ default: `absolute left-3`, business: "mr-0" }[mode])}/>);
};
