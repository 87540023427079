import { Dimensions } from "react-native";
/**
 * 10pt on Figma, translates to 1.76% of the device dimensions used in the
 * mock up. Use that same percentage to scale the font automatically,
 * based on device height. The below scale percentages have been further
 * manipulated as the ratio from Figma didn't work as precisely as one would expect.
 */
const DEVICE_DIMENSIONS = Dimensions.get("window");
const SCALE_8_FONT_SIZE_PERCENTAGE = 0.0124;
const SCALE_10_FONT_SIZE_PERCENTAGE = 0.0156;
const SCALE_12_FONT_SIZE_PERCENTAGE = 0.0189;
const SCALE_14_FONT_SIZE_PERCENTAGE = 0.021;
const SCALE_16_FONT_SIZE_PERCENTAGE = 0.025;
const SCALE_18_FONT_SIZE_PERCENTAGE = 0.029;
const SCALE_36_FONT_SIZE_PERCENTAGE = 0.058;
function calculateFontSize(figmaFontPercentage) {
    return figmaFontPercentage * DEVICE_DIMENSIONS.height;
}
export default {
    8: calculateFontSize(SCALE_8_FONT_SIZE_PERCENTAGE),
    10: calculateFontSize(SCALE_10_FONT_SIZE_PERCENTAGE),
    12: calculateFontSize(SCALE_12_FONT_SIZE_PERCENTAGE),
    14: calculateFontSize(SCALE_14_FONT_SIZE_PERCENTAGE),
    16: calculateFontSize(SCALE_16_FONT_SIZE_PERCENTAGE),
    18: calculateFontSize(SCALE_18_FONT_SIZE_PERCENTAGE),
    36: calculateFontSize(SCALE_36_FONT_SIZE_PERCENTAGE),
};
