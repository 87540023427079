import React from "react";
import { Pressable, View } from "react-native";
import tw from "../../../lib/tailwind";
export const Accordion = ({ header, body, containerStyle, headerContainerStyle, bodyContainerStyle, showBodyAlways, }) => {
    const [showBody, setShowBody] = React.useState(!!showBodyAlways);
    return (<View style={tw.style("w-full rounded-6 px-4 py-2 overflow-hidden bg-white", containerStyle)}>
      <Pressable style={tw.style("flex-1", headerContainerStyle)} onPress={() => {
            setShowBody((prev) => showBodyAlways || !prev);
        }}>
        {header}
      </Pressable>
      {showBody && (<View style={tw.style("border-t border-gray-500 mt-2 mx-2.5", bodyContainerStyle)}>
          {body}
        </View>)}
    </View>);
};
