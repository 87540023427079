var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useEffect } from "react";
import { View, Platform } from "react-native";
import { AppInput, AppInputLabel } from "./index";
import { Picker } from "@react-native-picker/picker";
import styleGuide from "../../../lib/style-guide";
import tw from "../../../lib/tailwind";
import colors from "../../../lib/style-guide/colors";
export const PhoneNumberField = forwardRef((props, ref) => {
    const { value, style, onChangeText, containerStyle, pickerStyle, innerRef, label, callingCodeValue, message, itemStyle, pickerTextStyle, color, enabled = true } = props, rest = __rest(props, ["value", "style", "onChangeText", "containerStyle", "pickerStyle", "innerRef", "label", "callingCodeValue", "message", "itemStyle", "pickerTextStyle", "color", "enabled"]);
    const countryData = [
        {
            callingCode: "254",
            countryCode: "KE",
            emoji: "🇰🇪",
            name: "Kenya",
        },
        {
            callingCode: "234",
            countryCode: "NG",
            emoji: "🇳🇬",
            name: "Nigeria",
        },
    ];
    const getEmoji = callingCodeValue === "234" || (value === null || value === void 0 ? void 0 : value.callingCode) === "234" ? "🇳🇬" : "🇰🇪";
    const [phoneNumber, setPhoneNumber] = React.useState(() => {
        var _a, _b;
        return ({
            number: (_a = value === null || value === void 0 ? void 0 : value.number) !== null && _a !== void 0 ? _a : "",
            callingCode: (_b = value === null || value === void 0 ? void 0 : value.callingCode) !== null && _b !== void 0 ? _b : callingCodeValue,
            emoji: getEmoji,
        });
    });
    useEffect(() => {
        if (innerRef) {
            innerRef({
                phoneNumber,
                setPhoneNumber: (nextPhoneNumber) => {
                    setPhoneNumber(nextPhoneNumber);
                    onChangeText === null || onChangeText === void 0 ? void 0 : onChangeText(nextPhoneNumber);
                },
            });
        }
    }, [onChangeText, innerRef, phoneNumber]);
    const onInputChangeText = (numberInput) => {
        setPhoneNumber((prevPhoneNumber) => {
            const nextPhoneNumber = Object.assign(Object.assign({}, prevPhoneNumber), { number: numberInput });
            onChangeText === null || onChangeText === void 0 ? void 0 : onChangeText(nextPhoneNumber);
            return nextPhoneNumber;
        });
    };
    const pickerColor = message
        ? {
            borderColor: styleGuide.Colors.shades.red[100],
            borderRadius: 16,
        }
        : {
            borderColor: styleGuide.Colors.shades.green[600],
            borderRadius: 16,
        };
    return (<View style={tw `w-full`}>
        {typeof label === "string" ? <AppInputLabel label={label}/> : label}
        <View style={tw.style("flex-row w-full justify-between", containerStyle, message ? "items-start" : "items-center")}>
          <View style={tw.style("w-[35%] mr-[2%] h-12 bg-white justify-center items-center", Platform.OS === "android" && "border border-gray-900", pickerColor, pickerStyle)}>
            <Picker mode="dropdown" enabled={enabled} selectedValue={phoneNumber.callingCode} style={[
            tw.style(pickerColor, "w-full h-full px-1"),
            pickerTextStyle,
        ]} itemStyle={pickerTextStyle} dropdownIconColor={colors.black} onValueChange={(itemValue) => {
            const emoji = itemValue === "254" ? "🇰🇪" : "🇳🇬";
            setPhoneNumber((prevPhoneNumber) => {
                const nextPhoneNumber = Object.assign(Object.assign({}, prevPhoneNumber), { callingCode: itemValue, emoji });
                onChangeText === null || onChangeText === void 0 ? void 0 : onChangeText(nextPhoneNumber);
                return nextPhoneNumber;
            });
        }}>
              <Picker.Item color={color} style={[{ fontSize: styleGuide.Typography[12] }, itemStyle]} label="Select Country" value=""/>
              {countryData === null || countryData === void 0 ? void 0 : countryData.map((item) => (<Picker.Item key={item === null || item === void 0 ? void 0 : item.callingCode} label={`${item.emoji}  +${item.callingCode}`} value={item === null || item === void 0 ? void 0 : item.callingCode} style={[{ fontSize: styleGuide.Typography[12] }, itemStyle]} color={color}/>))}
            </Picker>
          </View>
          <AppInput ref={ref} autoComplete="tel" keyboardType="phone-pad" placeholder="Phone Number" value={phoneNumber.number} style={tw.style("w-[63%]", style)} placeholderTextColor={styleGuide.Colors.shades.grey[1150]} onChangeText={(text) => onInputChangeText(text)} {...rest}/>
        </View>
      </View>);
});
