import React, { useCallback, useState } from "react";
import { ActivityIndicator, Pressable, Text, View, } from "react-native";
import styleGuide from "../../../lib/style-guide";
import tw from "../../../lib/tailwind";
export const Button = ({ title, onPress, type = "medium", mode = "default", inverted = false, loading: initialLoading = false, disabled, iconLeft, iconRight, containerStyle, textStyle, iconLeftContainerStyle, iconRightContainerStyle, activityIndicator, }) => {
    const [loading, setLoading] = useState(initialLoading);
    const handlePress = useCallback(() => {
        const result = onPress();
        if (result) {
            setLoading(true);
            result.finally(() => {
                setLoading(initialLoading);
            });
        }
    }, [initialLoading, onPress]);
    return (<Pressable onPress={handlePress} disabled={disabled} style={tw.style("flex-row rounded-2 py-3 px-6 items-center justify-center", {
            backgroundColor: {
                success: styleGuide.Colors.shades.grey[1500],
                danger: styleGuide.Colors.shades.red[100],
                default: styleGuide.Colors.white,
            }[mode],
        }, inverted && {
            backgroundColor: {
                success: styleGuide.Colors.white,
                danger: styleGuide.Colors.white,
                default: "transparent",
            }[mode],
            borderWidth: {
                success: 1,
                danger: 1,
                default: 0,
            }[mode],
            borderColor: {
                success: styleGuide.Colors.shades.grey[1500],
                danger: styleGuide.Colors.shades.red[100],
                default: "transparent",
            }[mode],
        }, {
            primary: "w-full justify-start px-4",
            long: "w-full",
            medium: "px-12",
            short: "py-1.5 px-5",
        }[type], disabled && "opacity-50", containerStyle)}>
      {iconLeft && (<View style={tw.style({
                primary: "border-2 border-white rounded-full p-1.5 mr-3",
                long: "mr-2",
                medium: "mr-2",
                short: "mr-2",
            }[type], iconLeftContainerStyle)}>
          {iconLeft}
        </View>)}
      {loading ? (<ActivityIndicator size={24} color={tw.style({
                success: "text-white",
                danger: "text-white",
                default: "text-gray-700",
            }[mode], inverted && {
                color: {
                    success: styleGuide.Colors.shades.grey[1500],
                    danger: styleGuide.Colors.shades.red[100],
                    default: styleGuide.Colors.shades.grey[1500],
                }[mode],
            }).color} {...activityIndicator}/>) : (<Text style={tw.style("font-700 text-base", {
                success: "text-white",
                danger: "text-white",
                default: "text-gray-700",
            }[mode], inverted && {
                color: {
                    success: styleGuide.Colors.shades.grey[1500],
                    danger: styleGuide.Colors.shades.red[100],
                    default: styleGuide.Colors.white,
                }[mode],
            }, {
                primary: "flex-1",
                long: "",
                medium: "",
                short: "text-sm",
            }[type], textStyle)}>
          {title}
        </Text>)}
      {iconRight && (<View style={tw.style({
                primary: "",
                long: "ml-2",
                medium: "ml-2",
                short: "ml-2",
            }[type], iconRightContainerStyle)}>
          {iconRight}
        </View>)}
    </Pressable>);
};
