import React from "react";
import { View, Text, Image, } from "react-native";
import StyleGuide from "../../../lib/style-guide";
import tw from "../../../lib/tailwind";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
export const Avatar = ({ name, imageProps, mode, size = "regular", containerStyle, textContainerStyle, textStyle, iconProps, }) => {
    const [firstText] = (name !== null && name !== void 0 ? name : "").split(" ");
    let customerInitials = `${firstText ? firstText[0] : ""}`;
    customerInitials = customerInitials.toUpperCase();
    const borderRadius = {
        large: "rounded-12",
        regular: "rounded-10",
        small: "rounded-8",
    }[size];
    return (<View style={tw.style("bg-[#DDF2F9] items-center justify-center", {
            large: "w-20 h-20",
            regular: "w-16 h-16",
            small: "w-12 h-12",
        }[size], borderRadius, containerStyle)}>
      {imageProps ? (<Image {...imageProps} style={tw.style(`w-full h-full`, { resizeMode: "contain" }, imageProps === null || imageProps === void 0 ? void 0 : imageProps.style)}/>) : customerInitials ? (<View style={tw.style(`items-center justify-center w-full h-full bg-gray-300`, borderRadius, textContainerStyle)}>
          <Text style={tw.style(`font-500 text-[${mode === "dark"
                ? StyleGuide.Colors.white
                : StyleGuide.Colors.shades.grey[1150]}]`, {
                large: "text-2xl",
                regular: "text-xl",
                small: "text-base",
            }[size], textStyle)}>
            {customerInitials}
          </Text>
        </View>) : (<MaterialCommunityIcons name={"account-outline"} size={36} color={tw.style("text-gray-500").color} {...iconProps}/>)}
    </View>);
};
