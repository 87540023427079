import * as React from "react";
import Svg, { Path } from "react-native-svg";

const SvgLock = (props) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m7.306 7.758.343 3.088-.694.055a2.111 2.111 0 0 0-1.915 1.764 20.641 20.641 0 0 0 0 6.67A2.111 2.111 0 0 0 6.955 21.1l1.496.12c2.362.188 4.736.188 7.098 0l1.496-.12a2.111 2.111 0 0 0 1.915-1.764 20.64 20.64 0 0 0 0-6.67 2.111 2.111 0 0 0-1.915-1.764l-.694-.055.343-3.088c.04-.364.04-.731 0-1.095l-.023-.205a4.7 4.7 0 0 0-9.342 0l-.023.205a4.96 4.96 0 0 0 0 1.095ZM12.374 3.8A3.2 3.2 0 0 0 8.82 6.624l-.023.205a3.46 3.46 0 0 0 0 .764l.349 3.139c1.9-.122 3.807-.122 5.708 0l.349-3.14a3.46 3.46 0 0 0 0-.763l-.023-.205a3.2 3.2 0 0 0-2.806-2.825ZM12 14.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      fill={props.color}
    />
  </Svg>
);

export default SvgLock;
