var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useEffect, useRef, useState } from "react";
import { View, Text, ActivityIndicator } from "react-native";
import { Header } from "../storybook/stories/Header";
import { Notification } from "../storybook/stories/Notification";
import tw from "../lib/tailwind";
import { OTPInput } from "../storybook/stories/Input/OtpInput";
import Modal from "react-native-modal";
import Lock from "../assets/icons/components/Outline/Status/Lock";
const PinVerificationModal = (_a) => {
    var { closeModal, pageInfo: initialPageInfo, confirmPageInfo, onPinSubmit } = _a, modalProps = __rest(_a, ["closeModal", "pageInfo", "confirmPageInfo", "onPinSubmit"]);
    const [code, setCode] = useState("");
    const [pageInfo, setPageInfo] = useState(initialPageInfo);
    useEffect(() => {
        setPageInfo(initialPageInfo);
    }, [initialPageInfo]);
    const pinRef = useRef();
    const [loading, setLoading] = useState(false);
    const handlePinSubmit = useCallback((submittedCode) => __awaiter(void 0, void 0, void 0, function* () {
        if (confirmPageInfo && confirmPageInfo.key !== pageInfo.key) {
            pinRef.current = submittedCode;
            setPageInfo(confirmPageInfo);
            setCode("");
        }
        else {
            setLoading(true);
            yield onPinSubmit(submittedCode, pinRef.current)
                .then(() => {
                closeModal === null || closeModal === void 0 ? void 0 : closeModal();
            })
                .finally(() => {
                pinRef.current = undefined;
                setPageInfo(initialPageInfo);
                setCode("");
                setLoading(false);
            });
        }
    }), [closeModal, confirmPageInfo, initialPageInfo, onPinSubmit, pageInfo.key]);
    return (<Modal style={tw `bg-white m-0`} onBackButtonPress={closeModal} 
    // onBackdropPress={closeModal}
    {...modalProps}>
      <View style={tw `flex-1`}>
        <Header title={pageInfo.headerTitle} onBackPress={closeModal}/>
        <View style={tw `mx-4 mt-4 items-center`}>
          <Notification message={"Do not share your PIN with anyone"} mode={"neutral"} style={tw `py-2.5 w-full`}/>
          <Text style={tw `mt-12 text-gray-900 text-base`}>
            {pageInfo.title}
          </Text>
          <View style={tw `flex-row items-center mt-3`}>
            <Lock width={16} height={16} color={tw.style("text-gray-600").color} style={tw `mr-2`}/>
            <Text style={tw `text-center text-gray-600 text-xs`}>
              All transactions are safe, secure and instant.
            </Text>
          </View>
          {!loading ? (<OTPInput autoFocusOnLoad={false} secureTextEntry style={tw `mt-12 h-24`} placeholderCharacter={""} code={code} keyboardType={"number-pad"} handleOtpChange={setCode} handleSubmit={handlePinSubmit} clearInputs={!code}/>) : (<ActivityIndicator size="large" color={tw `text-brand-green-accent`.color} style={tw `mt-16`}/>)}
        </View>
      </View>
    </Modal>);
};
export default PinVerificationModal;
