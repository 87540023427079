var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Modal from "react-native-modal";
import { Pressable, StyleSheet, View, Text } from "react-native";
import tw from "../lib/tailwind";
export const OptionsModal = (_a) => {
    var { closeModal, options } = _a, modalProps = __rest(_a, ["closeModal", "options"]);
    return (<Modal onBackdropPress={closeModal} onBackButtonPress={closeModal} {...modalProps}>
      <View style={styles.content}>
        {options.map((option) => {
            return (<Pressable onPress={() => {
                    option.onPress();
                    closeModal === null || closeModal === void 0 ? void 0 : closeModal();
                }} key={option.text}>
              <View style={tw.style("p-lg")}>
                <Text style={tw.style("text-base text-black", option.textStyle)}>
                  {option.text}
                </Text>
              </View>
            </Pressable>);
        })}
      </View>
    </Modal>);
};
const styles = StyleSheet.create({
    content: tw.style("bg-white py-sm", {
        borderRadius: 4,
    }),
});
