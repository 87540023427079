var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Modal from "react-native-modal";
import { SearchableDropdown, } from "../storybook/stories/SearchableDropdown";
import tw from "../lib/tailwind";
export const SearchModal = (_a) => {
    var { closeModal } = _a, restProps = __rest(_a, ["closeModal"]);
    return (<Modal onBackdropPress={closeModal} onBackButtonPress={closeModal} animationIn="slideInDown" animationOut="slideOutUp" style={tw.style("justify-start m-0")} {...restProps}>
      <SearchableDropdown {...restProps}/>
    </Modal>);
};
