var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import Modal from "react-native-modal";
import { StyleSheet, View } from "react-native";
import tw from "../lib/tailwind";
export const FullModal = (_a) => {
    var { closeModal, renderContent } = _a, modalProps = __rest(_a, ["closeModal", "renderContent"]);
    return (<Modal onBackdropPress={closeModal} onBackButtonPress={closeModal} style={tw.style("justify-end m-0")} {...modalProps}>
      <View style={styles.content}>{renderContent === null || renderContent === void 0 ? void 0 : renderContent({ closeModal })}</View>
    </Modal>);
};
const styles = StyleSheet.create({
    content: tw.style("flex-1 bg-white"),
});
