var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { View, Text } from "react-native";
import Modal from "react-native-modal";
import tw from "../lib/tailwind";
export const BottomHalfModal = (_a) => {
    var { title, renderContent, swipeDirection = ["down"], closeModal } = _a, modalProps = __rest(_a, ["title", "renderContent", "swipeDirection", "closeModal"]);
    return (<Modal onBackdropPress={closeModal} onBackButtonPress={closeModal} onSwipeComplete={closeModal} swipeDirection={swipeDirection} style={tw.style("justify-end m-0")} {...modalProps}>
      <View style={tw `px-4 bg-white items-center rounded-t-8 pb-6`}>
        <View style={tw `w-20 h-1 bg-gray-200 rounded-full mt-4 mb-6`}/>
        {!!title && <Text style={tw `text-lg font-500`}>{title}</Text>}
        <View style={tw `w-full`}>{renderContent === null || renderContent === void 0 ? void 0 : renderContent({ closeModal })}</View>
      </View>
    </Modal>);
};
