var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { memo } from "react";
import { ScrollView, View, Text, Linking, Pressable, } from "react-native";
import LogoFull from "../../../assets/logos/components/LogoFull";
import LogoGreyText from "../../../assets/logos/components/LogoGreyText";
import Cross from "../../../assets/icons/components/Solid/Interface/Cross";
import tw from "../../../lib/tailwind";
export const ReceiptDivider = ({ showSides = true, containerStyle, }) => (<View style={tw.style("flex-row items-center relative h-6", containerStyle)}>
    {showSides && (<View style={tw.style("absolute h-6 w-6 bg-[#E2E2E2] rounded-10 -right-3")}/>)}
    <View style={tw.style("border-b-2 border-[#DDDDDD] border-dashed w-full")}/>
    {showSides && (<View style={tw.style("absolute h-6 w-6 bg-[#E2E2E2] rounded-10 -left-3 ml-auto")}/>)}
  </View>);
export const ReceiptContainer = memo(({ business, children, onClose }) => {
    return (<ScrollView>
        <View style={tw.style("flex-1 bg-white")}>
          <View style={tw.style("items-center pt-6 px-6 pb-3 relative")}>
            {onClose && (<Pressable onPress={onClose} style={tw `absolute left-0.5 top-1`}>
                <Cross width={44} height={44} color={"black"}/>
              </Pressable>)}
            <LogoFull width={80}/>
          </View>
          <ReceiptDivider />
          <View style={tw `mt-4 mb-6`}>{children}</View>
          <View style={tw.style("flex-row items-center justify-center mb-6 px-6")}>
            <Text style={tw.style("text-xs text-[#B9B9B9]")}>Powered by </Text>
            <LogoGreyText width={60}/>
          </View>
          <Text style={tw.style("text-[#5E5959] text-xs text-center leading-5 mb-6 px-6")}>
            This receipt is proof of your legal obligation to repay the amount
            advance (including any interest and penalties) to {business.name}{" "}
            and those of {business.name} to Shara as outlined in the{" "}
            <Text onPress={() => __awaiter(void 0, void 0, void 0, function* () {
            const url = "https://shara.co/privacy.html";
            if (yield Linking.canOpenURL(url)) {
                yield Linking.openURL(url);
            }
        })} style={tw.style("underline")}>
              Terms & Conditions
            </Text>
            .
          </Text>
        </View>
      </ScrollView>);
});
