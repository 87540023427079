import React from "react";
import { View, Text } from "react-native";
import tw from "../../../lib/tailwind";
export const Tag = ({ text, colorVariant = "default", inverted = false, containerStyle, textStyle, }) => {
    return (<View style={tw.style("rounded-full py-1 px-3 items-center", {
            primary: "bg-brand-blue-300",
            success: "bg-brand-green-accent",
            danger: "bg-brand-red-accent",
            default: "bg-gray-500",
        }[colorVariant], inverted &&
            {
                primary: "bg-brand-blue-light",
                success: "bg-brand-green-light",
                danger: "bg-brand-red-light",
                default: "bg-gray-300",
            }[colorVariant], containerStyle)}>
      <Text style={tw.style("font-500", {
            primary: "text-white",
            success: "text-brand-green-light",
            danger: "text-white",
            default: "text-white",
        }[colorVariant], inverted &&
            {
                primary: "text-brand-blue-300",
                success: "text-brand-green-accent",
                danger: "text-brand-red-accent",
                default: "text-gray-700",
            }[colorVariant], textStyle)}>
        {text}
      </Text>
    </View>);
};
