var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, useState } from "react";
import { TouchableOpacity } from "react-native";
import { AppInput } from "./";
import styleGuide from "../../../lib/style-guide";
import { Ionicons } from "@expo/vector-icons";
export const PasswordInput = forwardRef((props, ref) => {
    const { onChangeText, value: initialValue = "" } = props, rest = __rest(props, ["onChangeText", "value"]);
    const [secure, setSecure] = React.useState(true);
    const toggleSecure = () => {
        setSecure(!secure);
    };
    const [value, setValue] = useState(initialValue);
    return (<AppInput ref={ref} value={value} autoCapitalize="none" secureTextEntry={secure} placeholder="**************" onChangeText={(text) => {
            onChangeText && onChangeText(text);
            setValue(text);
        }} rightIcon={<TouchableOpacity onPress={toggleSecure}>
          {secure ? (<Ionicons name="eye-off-outline" color={styleGuide.Colors.shades.grey[100]} size={14}/>) : (<Ionicons name="eye-outline" color={styleGuide.Colors.shades.grey[100]} size={14}/>)}
        </TouchableOpacity>} {...rest}/>);
});
