var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Picker, } from "@react-native-picker/picker";
import tw from "../../../lib/tailwind";
import { View } from "react-native";
import { AppInputLabel } from "../Input";
export const SelectInput = (_a) => {
    var { options, containerStyle, label } = _a, props = __rest(_a, ["options", "containerStyle", "label"]);
    return (<View style={tw `w-full`}>
      {label && <AppInputLabel label={label}/>}
      <View style={tw.style(`p-4 rounded-4 bg-white border-[#8D8D8D] border text-base`, containerStyle)}>
        <Picker {...props} style={tw.style(`text-sm border-0`, props.style)}>
          {options.map((option) => {
            return <Picker.Item key={option.value} {...option}/>;
        })}
        </Picker>
      </View>
    </View>);
};
