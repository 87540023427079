var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, forwardRef } from "react";
import { TextInput, View, Text, Pressable, } from "react-native";
import InfoCircle from "../../../assets/icons/components/Solid/Status/InfoCircle";
import { mergeRefs } from "../../../utils";
import tw from "../../../lib/tailwind";
export const AppInputLabel = (_a) => {
    var { style, label } = _a, props = __rest(_a, ["style", "label"]);
    return (<Text style={tw.style(`flex-1 font-500 mb-1 text-gray-900 text-sm`, style)} {...props}>
      {label}
    </Text>);
};
export const AppInput = forwardRef((props, inputRef) => {
    const { label, isInvalid, containerStyle, leftIcon, leftIconContainerStyle, textInputContainerStyle, rightIcon, rightIconContainerStyle, message, messageIcon = true, messageStyle, messageContainerStyle, style, editable = true, onBlur, onFocus } = props, rest = __rest(props, ["label", "isInvalid", "containerStyle", "leftIcon", "leftIconContainerStyle", "textInputContainerStyle", "rightIcon", "rightIconContainerStyle", "message", "messageIcon", "messageStyle", "messageContainerStyle", "style", "editable", "onBlur", "onFocus"]);
    const [focused, setFocused] = useState(false);
    const localInputRef = React.useRef(null);
    const handleFocus = React.useCallback((e) => {
        setFocused(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(e);
    }, [onFocus]);
    const handleBlur = React.useCallback((e) => {
        setFocused(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    }, [onBlur]);
    return (<View style={tw.style("w-full", containerStyle)}>
        {typeof label === "string" ? <AppInputLabel label={label}/> : label}
        <View style={tw.style(``, textInputContainerStyle)}>
          {leftIcon && (<Pressable onPress={() => {
                var _a;
                (_a = localInputRef === null || localInputRef === void 0 ? void 0 : localInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }} style={tw.style(`flex-row items-center justify-center absolute left-2.5 top-0 z-10 h-full`, leftIconContainerStyle)}>
              {leftIcon}
            </Pressable>)}
          <TextInput ref={mergeRefs([localInputRef, inputRef])} onFocus={handleFocus} onBlur={handleBlur} style={tw.style("w-full h-12 font-400 text-base text-black border border-gray-500 rounded-4 bg-white px-4 outline-0", focused && "border-brand-green-accent", isInvalid && "border-red-500", !editable && "border-0 text-gray-500 bg-gray-100", !!leftIcon && "pl-10", !!rightIcon && "pr-10", style)} placeholderTextColor={tw.style(!editable ? `text-gray-100` : `text-gray-500`)
            .color} editable={editable} {...rest}/>
          {rightIcon && (<Pressable onPress={() => {
                var _a;
                (_a = localInputRef === null || localInputRef === void 0 ? void 0 : localInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            }} style={tw.style("flex-row justify-center items-center absolute right-2.5 top-0 z-10 h-full", rightIconContainerStyle)}>
              {rightIcon}
            </Pressable>)}
        </View>
        {!!message && (<View style={tw.style(`mt-1 items-center flex-row`, messageContainerStyle)}>
            {messageIcon && typeof messageIcon === "boolean" ? (<InfoCircle width={14} height={14} color={tw.style(isInvalid ? `text-red-500` : `text-gray-500`)
                    .color} style={tw.style(`mr-1`)}/>) : (messageIcon)}
            <Text style={tw.style(`font-400 text-xs`, isInvalid ? "text-red-500" : "text-gray-900", messageStyle)}>
              {message}
            </Text>
          </View>)}
      </View>);
});
