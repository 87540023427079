import React from "react";
import { View, Text, StyleSheet } from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import ordinal from "ordinal";
import StyleGuide from "../../../lib/style-guide";
import { Button } from "../Button";
import { isBefore, isSameMonth, isToday } from "date-fns";
export const Instalment = ({ amount, dueDate, position, currency, mode = "pending", onPress, }) => {
    const dueDateObj = new Date(dueDate);
    const today = new Date();
    let dueTag = "";
    if (mode !== "paid") {
        if (isSameMonth(dueDateObj, today)) {
            dueTag = "Due This Month";
            mode = "due";
        }
        if (isBefore(dueDateObj, today)) {
            dueTag = "Late";
            mode = "late";
        }
        if (isToday(dueDateObj)) {
            dueTag = "Due Today";
            mode = "due";
        }
    }
    const firstInstalmentNote = mode !== "late"
        ? styles.firstInstalmentNote
        : styles.firstInstalmentNoteLate;
    const instalmentOrdinal = mode === "pending"
        ? styles.instalmentOrdinal
        : [styles.instalmentOrdinal, styles.instalmentOrdinalDue];
    const dueDateStyle = mode === "pending"
        ? styles.dueDate
        : [styles.dueDate, styles.dueDateForDue];
    const amountStyling = mode === "pending" || mode === "paid"
        ? styles.amountForDue
        : [styles.amountForDue, styles.bold];
    const amountTagContainerStyle = mode === "late"
        ? [styles.amountTag, styles.amountLateTag]
        : styles.amountTag;
    const amountTagTextStyle = mode === "late"
        ? [styles.amountTagText, styles.amountLateTagText]
        : styles.amountTagText;
    const instalmentActionStyle = mode === "paid"
        ? [styles.instalmentAction, styles.instalmentActionPaid]
        : styles.instalmentAction;
    return (<View style={styles.coreContainer}>
      {/*Left Icon Section*/}
      <View style={styles.leftIconContainer}>
        {mode === "late" && (<View style={styles.leftIconLate}>
            <MaterialIcons name="timer" size={20} color={StyleGuide.Colors.shades.red[700]}/>
          </View>)}

        {mode === "due" && (<View style={styles.leftIconDue}>
            <MaterialIcons name="timer" size={20} color={StyleGuide.Colors.shades.grey[1250]}/>
          </View>)}

        {mode === "paid" && (<MaterialCommunityIcons name="check-all" color={StyleGuide.Colors.shades.green[100]} size={24}/>)}
      </View>

      {/*Main Details Section*/}
      <View style={styles.mainContainer}>
        <View style={styles.instalmentDetails}>
          <View style={styles.amountContainer}>
            <Text style={amountStyling}>
              {currency}
              {amount}
            </Text>

            {mode !== "paid" && mode !== "pending" && (<View style={amountTagContainerStyle}>
                <Text style={amountTagTextStyle}>{dueTag}</Text>
              </View>)}
          </View>

          {mode !== "paid" && (<Text style={dueDateStyle}>Pay before {dueDate}</Text>)}

          {mode === "paid" && position === 1 && (<Text style={styles.downpayment}>25% down payment</Text>)}

          {position === 1 && mode !== "paid" && (<Text style={firstInstalmentNote}>
              This transaction will be cancelled if not paid in 24hrs
            </Text>)}

          {position !== 1 && mode === "late" && (<Text style={[
                styles.firstInstalmentNote,
                styles.firstInstalmentNoteLate,
            ]}>
              Late fees of{" "}
              <Text style={[
                styles.firstInstalmentNote,
                styles.firstInstalmentNoteLate,
                styles.bold,
            ]}>
                KSH 120
              </Text>{" "}
              has been added to this payment. Pay now to avoid more late fees.
            </Text>)}

          {mode === "due" && position !== 1 && (<Text style={styles.duePrompt}>Pay on time to avoid late fees</Text>)}
        </View>

        {/*Call To Action Section*/}
        <View style={instalmentActionStyle}>
          <Text style={instalmentOrdinal}>
            {ordinal(position)} instalment {mode === "paid" && "Paid:"}
          </Text>
          {(mode === "due" || mode === "late") && onPress && (<Button title="Pay" iconRight={<MaterialIcons name="arrow-forward" color="white" size={24}/>} onPress={onPress}/>)}

          {mode === "paid" && <Text style={styles.paidDate}>{dueDate}</Text>}
        </View>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    coreContainer: {
        flexDirection: "row",
        width: "100%",
        paddingHorizontal: 16,
        marginTop: 16,
    },
    leftIconContainer: {
        width: 48,
        justifyContent: "center",
    },
    leftIconLate: {
        backgroundColor: StyleGuide.Colors.shades.red[50],
        justifyContent: "center",
        alignItems: "center",
        width: 36,
        height: 36,
        borderRadius: 13,
    },
    leftIconDue: {
        backgroundColor: "transparent",
        justifyContent: "center",
        alignItems: "center",
        width: 36,
        height: 36,
        borderRadius: 20,
    },
    mainContainer: {
        flexDirection: "row",
        flex: 1,
        borderBottomColor: StyleGuide.Colors.shades.grey[50],
        borderBottomWidth: 1,
        paddingBottom: 8,
    },
    instalmentDetails: {
        flex: 1,
    },
    amountContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    amountForDue: {
        color: StyleGuide.Colors.shades.grey[1150],
        fontSize: StyleGuide.Typography[14],
        marginBottom: 4,
    },
    amountForPending: {
        color: StyleGuide.Colors.shades.grey[1200],
        fontSize: StyleGuide.Typography[14],
        marginBottom: 4,
    },
    amountTag: {
        backgroundColor: "rgba(9, 213, 5, 0.12)",
        height: 18,
        borderRadius: 8,
        marginLeft: 5,
        paddingHorizontal: 4,
    },
    amountTagText: {
        color: StyleGuide.Colors.shades.green[700],
        fontSize: StyleGuide.Typography[10],
    },
    amountLateTag: {
        backgroundColor: StyleGuide.Colors.shades.red[25],
    },
    amountLateTagText: {
        color: StyleGuide.Colors.shades.red[700],
    },
    bold: {
        fontWeight: "bold",
    },
    dueDate: {
        color: StyleGuide.Colors.shades.grey[1200],
        fontSize: StyleGuide.Typography[12],
        marginBottom: 4,
    },
    dueDateForDue: {
        color: StyleGuide.Colors.shades.grey[1150],
    },
    downpayment: {
        color: StyleGuide.Colors.shades.grey[1200],
        fontSize: StyleGuide.Typography[12],
        marginBottom: 4,
    },
    duePrompt: {
        color: StyleGuide.Colors.shades.grey[100],
        fontSize: StyleGuide.Typography[10],
    },
    paidDate: {
        fontSize: StyleGuide.Typography[10],
        color: StyleGuide.Colors.shades.grey[1200],
    },
    firstInstalmentNote: {
        color: StyleGuide.Colors.shades.grey[1200],
        fontSize: StyleGuide.Typography[10],
    },
    firstInstalmentNoteLate: {
        color: StyleGuide.Colors.shades.red[700],
    },
    instalmentAction: {
        width: 100,
        alignItems: "flex-end",
        justifyContent: "center",
    },
    instalmentActionPaid: {
        width: 150,
    },
    instalmentOrdinal: {
        color: StyleGuide.Colors.shades.grey[1200],
        fontSize: StyleGuide.Typography[10],
        marginBottom: 5,
    },
    instalmentOrdinalDue: {
        color: StyleGuide.Colors.shades.grey[1150],
    },
});
