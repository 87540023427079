var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { ActivityIndicator, View, Text } from "react-native";
import Modal from "react-native-modal";
import tw from "../lib/tailwind";
export const LoadingModal = (_a) => {
    var { text, closeModal } = _a, modalProps = __rest(_a, ["text", "closeModal"]);
    return (<Modal onBackdropPress={closeModal} onBackButtonPress={closeModal} style={tw.style("m-0")} {...modalProps}>
      <View style={tw `bg-white flex-1 items-center justify-center`}>
        <ActivityIndicator size="large" color={tw `text-brand-green-accent`.color} style={tw `mb-8`}/>
        <Text style={tw `mx-12 text-center`}>{text}</Text>
      </View>
    </Modal>);
};
