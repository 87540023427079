export * from "./scalers";
export * from "./validation";
export * from "./transformCase";
export const flattenObject = (object, prefix = "", seperator = "-") => {
    let result = {};
    Object.keys(object).forEach((name) => {
        const key = `${prefix}${prefix ? seperator : ""}${name}`;
        let value = object[name];
        if (typeof value === "string") {
            result[key] = value;
        }
        else if (typeof value === "object") {
            result = Object.assign(Object.assign({}, result), flattenObject(value, key));
        }
    });
    return result;
};
/**
 * https://github.com/gregberge/react-merge-refs
 */
export function mergeRefs(refs) {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            }
            else if (ref != null) {
                ref.current = value;
            }
        });
    };
}
