var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import OTPInputView from "@twotalltotems/react-native-otp-input";
import { View, StyleSheet } from "react-native";
import styleGuide from "../../../lib/style-guide";
export const OTPInput = (_a) => {
    var { handleSubmit, handleOtpChange, pinCount = 4, placeholder } = _a, props = __rest(_a, ["handleSubmit", "handleOtpChange", "pinCount", "placeholder"]);
    return (<View style={styles.container}>
      <OTPInputView pinCount={pinCount} placeholderCharacter={placeholder} style={styles.otpContainer} onCodeFilled={handleSubmit} placeholderTextColor="#000000" onCodeChanged={handleOtpChange} codeInputFieldStyle={styles.otpInput} codeInputHighlightStyle={styles.otpInputHighlight} {...props}/>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        width: "80%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    otpContainer: {
        width: "100%",
        height: 20,
    },
    otpInput: {
        width: 35,
        color: styleGuide.Colors.black,
        fontSize: styleGuide.Typography[18],
        borderWidth: 0,
        borderRadius: 0,
        borderBottomWidth: 1,
        borderBottomColor: styleGuide.Colors.shades.grey[100],
    },
    otpInputHighlight: {
        borderBottomColor: styleGuide.Colors.shades.green[600],
    },
});
