import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";
import { Feather } from "@expo/vector-icons";
import styleGuide from "../../../lib/style-guide";
export const Checkbox = (props) => {
    const { style, value: initialValue, onChange, leftLabel, rightLabel, containerStyle, disabled = false, checkedColor = styleGuide.Colors.shades.green[600], borderColor = styleGuide.Colors.shades.green[600], iconSize, } = props;
    const [checked, setChecked] = useState(initialValue);
    useEffect(() => {
        setChecked((prevChecked) => {
            if (prevChecked !== initialValue) {
                return initialValue;
            }
            return prevChecked;
        });
    }, [initialValue]);
    const borderStyles = useMemo(() => {
        if (disabled && checked) {
            return {
                borderWidth: 2,
                borderColor: styleGuide.Colors.shades.grey[100],
            };
        }
        if (checked) {
            return { borderWidth: 2, borderColor };
        }
        return { borderWidth: 2, borderColor: styleGuide.Colors.shades.grey[1250] };
    }, [borderColor, disabled, checked]);
    const bgColor = useMemo(() => {
        if (disabled && checked) {
            return { backgroundColor: styleGuide.Colors.shades.grey[100] };
        }
        if (checked) {
            return { backgroundColor: checkedColor };
        }
        return { backgroundColor: styleGuide.Colors.white };
    }, [disabled, checked, checkedColor]);
    const onPress = useCallback(() => {
        setChecked((prevValue) => {
            const nextValue = !prevValue;
            onChange === null || onChange === void 0 ? void 0 : onChange(nextValue);
            return nextValue;
        });
    }, [onChange]);
    return (<TouchableOpacity onPress={disabled ? undefined : onPress}>
      <View style={[styles.container, containerStyle]}>
        {leftLabel}
        <View style={[styles.checkCover, bgColor, borderStyles, style]}>
          {checked && (<Feather name="check" size={iconSize ? iconSize : 18} color={styleGuide.Colors.white}/>)}
        </View>
        {rightLabel}
      </View>
    </TouchableOpacity>);
};
const styles = StyleSheet.create({
    container: { flexDirection: "row", alignItems: "center" },
    checkCover: { width: 24, height: 24, borderRadius: 4 },
});
