import React from "react";
import { View, StyleSheet, Text, Pressable } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import StyleGuide from "../../../lib/style-guide";
import tw from "../../../lib/tailwind";
export const Notification = ({ showIcon = false, style, textStyle, message, mode = "primary", icon, onPress, }) => {
    const spacing = showIcon
        ? { justifyContent: "flex-start" }
        : { justifyContent: "center" };
    const alignText = showIcon ? { textAlign: "left" } : { textAlign: "center" };
    return (<Pressable style={[styles[`${mode}Container`], styles.container, spacing, style]} onPress={onPress}>
      {showIcon && (<View style={[styles[`${mode}IconContainer`], styles.iconContainer]}>
          {icon ? (icon) : (<MaterialCommunityIcons name="bell" size={12} color={StyleGuide.Colors.white}/>)}
        </View>)}
      {typeof message === "string" ? (<Text style={[
                tw.style(!showIcon ? "w-3/4" : "w-full"),
                styles.textStyle,
                styles[`${mode}TextStyle`],
                alignText,
                textStyle,
            ]}>
          {message}
        </Text>) : (message)}
    </Pressable>);
};
const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 12,
        padding: 16,
    },
    textStyle: {
        fontSize: StyleGuide.Typography[10],
        fontWeight: "500",
    },
    iconContainer: {
        width: 22,
        height: 22,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 12,
        marginRight: 12,
    },
    primaryContainer: {
        backgroundColor: StyleGuide.Colors.shades.blue[800],
    },
    primaryTextStyle: {
        color: StyleGuide.Colors.shades.blue[900],
    },
    primaryIconContainer: {
        backgroundColor: StyleGuide.Colors.shades.blue[900],
    },
    successContainer: {
        backgroundColor: StyleGuide.Colors.shades.green[900],
    },
    successTextStyle: {
        color: StyleGuide.Colors.shades.green[600],
    },
    successIconContainer: {
        backgroundColor: StyleGuide.Colors.shades.green[600],
    },
    errorContainer: {
        backgroundColor: StyleGuide.Colors.shades.red[200],
    },
    errorTextStyle: {
        color: StyleGuide.Colors.shades.red[100],
    },
    errorIconContainer: {
        backgroundColor: StyleGuide.Colors.shades.red[100],
    },
    warningContainer: {
        backgroundColor: StyleGuide.Colors.shades.orange[500],
    },
    warningTextStyle: {
        color: StyleGuide.Colors.shades.orange[400],
    },
    warningIconContainer: {
        backgroundColor: StyleGuide.Colors.shades.orange[400],
    },
    neutralContainer: {
        backgroundColor: StyleGuide.Colors.shades.grey[1000],
    },
    neutralTextStyle: {
        color: StyleGuide.Colors.black,
    },
    neutralIconContainer: {
        backgroundColor: StyleGuide.Colors.black,
    },
    disabledContainer: {
        backgroundColor: StyleGuide.Colors.shades.grey[1400],
    },
    disabledTextStyle: {
        color: StyleGuide.Colors.shades.grey[1450],
    },
    disabledIconContainer: {
        backgroundColor: StyleGuide.Colors.shades.grey[1450],
    },
});
