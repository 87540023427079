"use strict";
/** @type {import('tailwindcss').Config} */
module.exports = {
    content: [],
    theme: {
        fontFamily: {
            body: ["Roboto-Regular"],
            "body-medium": ["Roboto-Medium"],
            "body-bold": ["Roboto-Bold"],
            display: ["Hellix-Regular"],
            "display-bold": ["Hellix-Bold"],
        },
        extend: {
            colors: {
                brand: {
                    "gray-600": "#5E5959",
                    "gray-500": "#8D8D8D",
                    "gray-400": "#B9B9B9",
                    "gray-300": "#DDDDDD",
                    "gray-200": "#ECECEC",
                    "gray-100": "#F5F5F5",
                    "gray-light": "#EFF2F7",
                    "green-500": "#104B3D",
                    "green-400": "#1B8B70",
                    "green-accent": "#25A36E",
                    "green-300": "#25A36E",
                    "green-200": "#6CCAA8",
                    "green-100": "#C6D9D3",
                    "green-light": "#E7FFEE",
                    "red-500": "#8D1313",
                    "red-400": "#BE0303",
                    "red-accent": "#E85656",
                    "red-300": "#E85656",
                    "red-200": "#F4B1B1",
                    "red-100": "#FFEDE9",
                    "red-light": "#FFEDE9",
                    "blue-300": "#386EC4",
                    "blue-link": "#0391FC",
                    "blue-200": "#71A8FF",
                    "blue-accent": "#6DC4F4",
                    "blue-100": "#D8E6FB",
                    "blue-light": "#D8E6FB",
                    "orange-accent": "#EB8565",
                    "orange-light": "#FFEADE",
                    "purple-accent": "#CC71EC",
                    "purple-light": "#F9EAFF",
                    "yellow-accent": "#F5AB3D",
                    "yellow-light": "#FFF5DB",
                },
                whatsapp: "#1BA058",
            },
            fontSize: {
                xxs: [10, 16],
            },
        },
    },
    plugins: [],
};
