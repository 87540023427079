/*
  Code inspired by work done here
  https://github.com/zubairpaizer/react-native-searchable-dropdown/blob/master/index.js
*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FlatList, Keyboard, StyleSheet, View, Text, } from "react-native";
import debounce from "lodash/debounce";
import tw from "../../../lib/tailwind";
import { Button } from "../Button";
import { AppInput } from "../Input";
import Search from "../../../assets/icons/components/Solid/Interface/Search";
export function SearchableDropdown({ items, setFilter, onFocus, onBlur, renderItem, onChangeText, onItemSelect, textInputProps, emptyStateText, noResultsAction, noResultsActionButtonText, }) {
    const queryRef = useRef("");
    const [focus, setFocus] = useState(false);
    const [listItems, setListItems] = useState(items || []);
    const handleItemSelect = useCallback((item) => {
        Keyboard.dismiss();
        setFocus(false);
        if (onItemSelect) {
            setTimeout(() => {
                onItemSelect(item);
            }, 10);
        }
    }, [onItemSelect]);
    useEffect(() => {
        if (focus) {
            onFocus === null || onFocus === void 0 ? void 0 : onFocus();
        }
        else {
            onBlur === null || onBlur === void 0 ? void 0 : onBlur(queryRef.current);
        }
    }, [focus, onBlur, onFocus]);
    const handleInputFocus = useCallback(() => {
        setFocus(true);
        setListItems(items);
    }, [items]);
    const handleChangeText = useCallback(debounce((searchedText) => {
        queryRef.current = searchedText;
        const searchValue = searchedText.trim().toLowerCase();
        const results = items.filter((item) => {
            return setFilter(item, searchValue);
        });
        setListItems(results);
        const onTextChange = onChangeText || (textInputProps === null || textInputProps === void 0 ? void 0 : textInputProps.onChangeText);
        if (onTextChange && typeof onTextChange === "function") {
            setTimeout(() => {
                onTextChange(searchedText);
            }, 0);
        }
    }, 750), [items, onChangeText, setFilter, textInputProps]);
    const renderFlatList = useCallback(() => {
        if (focus) {
            return (<View style={tw.style({
                    top: 60,
                    zIndex: 100,
                    elevation: 2,
                    width: "100%",
                    position: "absolute",
                    backgroundColor: "#FFF",
                })}>
          {listItems.length ? (<FlatList data={listItems} 
                //@ts-ignore
                renderItem={({ item }) => renderItem({ item, onPress: handleItemSelect })} keyExtractor={(item, index) => index.toString()} ListEmptyComponent={<View style={tw.style("px-lg flex-1 items-center justify-center", {
                            paddingVertical: 100,
                        })}>
                  <Text style={tw.style("mb-xs heading-700", "text-center", {
                            color: "#363636",
                        })}>
                    No results found
                  </Text>
                  {noResultsAction && (<Button onPress={noResultsAction} containerStyle={tw.style("w-full")} title={noResultsActionButtonText}/>)}
                </View>}/>) : (<View style={styles.emptyState}>
              <Text style={tw.style("heading-700", styles.emptyStateText, styles.emptyStateHeading)}>
                No results found
              </Text>
              <Text style={tw.style("text-400", styles.emptyStateText, styles.emptyStateDescription)}>
                {emptyStateText}
              </Text>
            </View>)}
        </View>);
        }
    }, [
        emptyStateText,
        focus,
        handleItemSelect,
        listItems,
        noResultsAction,
        noResultsActionButtonText,
        renderItem,
    ]);
    const renderTextInput = useCallback(() => {
        return (<View style={styles.searchContainer}>
        <View style={styles.searchInputContainer}>
          <Search width={24} height={24} style={styles.searchInputIcon} color={"#5E5959"}/>
          <AppInput onFocus={handleInputFocus} onChangeText={handleChangeText} placeholderTextColor={"#B9B9B9"} containerStyle={tw.style(styles.searchInput, "text-400")} {...textInputProps}/>
        </View>
      </View>);
    }, [handleInputFocus, handleChangeText, textInputProps]);
    return (<View>
      {renderTextInput()}
      {renderFlatList()}
    </View>);
}
const styles = StyleSheet.create({
    searchContainer: {
        backgroundColor: "#104B3D",
    },
    searchInputContainer: {
        position: "relative",
    },
    searchInputIcon: {
        top: 12,
        left: 10,
        elevation: 3,
        position: "absolute",
    },
    searchInput: {
        height: 48,
        elevation: 2,
        fontSize: 16,
        paddingLeft: 48,
        backgroundColor: "#FFF",
    },
    emptyState: {
        paddingVertical: 50,
        alignItems: "center",
        justifyContent: "center",
    },
    emptyStateText: {
        textAlign: "center",
    },
    emptyStateHeading: {
        fontSize: 16,
        color: "#363636",
    },
    emptyStateDescription: {
        fontSize: 14,
        maxWidth: 300,
        textAlign: "center",
        color: "#5E5959",
        marginHorizontal: "auto",
    },
});
