import React from "react";
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import formatter from "format-number";
import StyleGuide from "../../../lib/style-guide";
import { Avatar } from "../Avatar";
export const Bill = ({ title, description, amount, dueDate, currency, mode = "active", hideBottomBorder = false, isChecked = false, onPress, }) => {
    const amountStyles = mode === "late" ? [styles.amount, styles.colorRed] : styles.amount;
    const dueDateStyles = mode === "late" ? [styles.dueDate, styles.colorRed] : styles.dueDate;
    const detailsContainerStyles = hideBottomBorder
        ? [styles.detailsContainer, styles.detailsContainerNoBottomBorder]
        : styles.detailsContainer;
    return (<View style={styles.container}>
      <View style={styles.avatarCheckContainer}>
        <View style={styles.checkboxContainer}>
          {mode !== "pending" && (<BouncyCheckbox size={18} isChecked={isChecked} fillColor={StyleGuide.Colors.shades.green[600]} unfillColor={"transparent"} 
        // eslint-disable-next-line react-native/no-inline-styles
        iconStyle={{
                borderRadius: 0,
                borderColor: isChecked
                    ? StyleGuide.Colors.shades.green[600]
                    : StyleGuide.Colors.shades.grey[1100],
            }}/>)}

          {mode === "pending" && (<View style={styles.pendingIconContainer}>
              <MaterialIcons name="brightness-auto" size={24} color={StyleGuide.Colors.shades.orange[300]}/>
            </View>)}
        </View>
        {mode === "active" && <Avatar name={title}/>}
        {mode === "pending" && <Avatar name={title} mode="dark"/>}

        {mode === "late" && (<View style={styles.lateAvatar}>
            <MaterialIcons name="timer" size={24} color={StyleGuide.Colors.shades.red[700]}/>
          </View>)}
      </View>
      <View style={detailsContainerStyles}>
        <View>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.description}>{description}</Text>
        </View>
        <View style={styles.amountContainer}>
          <Text style={amountStyles}>
            {currency}
            {formatter()(amount)}
          </Text>
          {mode !== "pending" && <Text style={dueDateStyles}>{dueDate}</Text>}
          <TouchableOpacity onPress={onPress}>
            <Text style={styles.viewDetails}>View details</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        width: "100%",
        paddingHorizontal: 16,
        marginTop: 12,
    },
    avatarCheckContainer: {
        flexDirection: "row",
        width: "30%",
        alignItems: "flex-start",
    },
    checkboxContainer: {
        justifyContent: "center",
        height: 50,
    },
    detailsContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        flex: 1,
        paddingBottom: 12,
        borderBottomColor: StyleGuide.Colors.shades.grey[50],
        borderBottomWidth: 1,
    },
    detailsContainerNoBottomBorder: {
        borderBottomWidth: 0,
    },
    colorRed: {
        color: StyleGuide.Colors.shades.red[600],
    },
    lateAvatar: {
        backgroundColor: StyleGuide.Colors.shades.red[50],
        width: 48,
        height: 48,
        borderRadius: 21,
        justifyContent: "center",
        alignItems: "center",
    },
    pendingIconContainer: {
        marginRight: 10,
    },
    title: {
        fontSize: StyleGuide.Typography[12],
        color: StyleGuide.Colors.shades.grey[1150],
        marginBottom: 4,
    },
    description: {
        fontSize: StyleGuide.Typography[12],
        color: StyleGuide.Colors.shades.grey[1200],
    },
    amountContainer: {
        alignItems: "flex-end",
    },
    amount: {
        fontSize: StyleGuide.Typography[12],
        fontWeight: "bold",
        color: StyleGuide.Colors.shades.grey[1150],
        marginBottom: 4,
    },
    dueDate: {
        fontSize: StyleGuide.Typography[10],
        color: StyleGuide.Colors.shades.grey[1200],
        marginBottom: 4,
    },
    viewDetails: {
        color: StyleGuide.Colors.shades.blue[700],
        fontSize: StyleGuide.Typography[10],
        fontWeight: "500",
    },
});
